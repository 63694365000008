import { GRID_MARKET_MODE, SWITCHER_ICON_SIZES } from 'src/constants/application';
import {
  TAssetFieldTemplatesArgs,
  TFieldsTemplateUnion,
  TNumberFieldTemplate,
  TTextFieldTemplate,
} from 'src/utils/assetsFields/assetsFields.types';

import { AreaPhoenix } from 'src/utils/assetsFields/fieldTemplates/AreaPhoenix';
import { EFormVariant } from 'src/typings/base-types';
import { HeatPumpPhoenix } from 'src/utils/assetsFields/fieldTemplates/HeatPumpPhoenix';
import { InfiniteBusPhoenix } from 'src/utils/assetsFields/fieldTemplates/InfiniteBusPhoenix';
import { PVPhoenix } from 'src/utils/assetsFields/fieldTemplates/PVPhoenix';
import { SpotMarketType } from 'src/graphql';
import { StoragePhoenix } from 'src/utils/assetsFields/fieldTemplates/StoragePhoenix';
import { WindTurbinePhoenix } from 'src/utils/assetsFields/fieldTemplates/WindTurbinePhoenix';
import { SmartMeterPhoenix } from 'src/utils/assetsFields/fieldTemplates/SmartMeterPhoenix';

// Template of node's fields. They are listed in order in which they should appear in form.

/* Defaults */
const defaultValues = {
  fitToLimit: true,
};

/*
 * Fields used to show API / Properties tabs
 * Shared between all entities
 */
// const KPI_API_PROPERTIES_FIELDS = ({
//   kpis,
//   type,
//   values = {},
//   configType,
//   isLibrary,
// }: Pick<TAssetFieldTemplatesArgs, 'type' | 'values' | 'configType' | 'isLibrary'> & {
//   kpis:
//     | NonNullable<TSettingsData['kpis']>['Area']
//     | NonNullable<TSettingsData['kpis']>['Device'];
// }): TFieldsTemplateUnion[] => {
//   if (isLibrary) return [];

//   const output: TFieldsTemplateUnion[] = [
//     {
//       name: 'allowExternalConnection',
//       type: 'switcher',
//       options: [
//         { icon: 'check-mark', iconSize: iconSize.tick, value: true },
//         { icon: 'close', iconSize: iconSize.close, value: false },
//       ],
//       label: 'API open',
//       formView: EFormVariant.KpiSettings,
//     },
//     {
//       name: 'targetKpi',
//       type: 'enum',
//       label: 'Select KPI',
//       options: [...kpis],
//       formView: EFormVariant.KpiSettings,
//     },
//   ];

//   if (['Load', 'PV'].includes(type)) {
//     if (configType === ConfigType.CanaryNetwork) {
//       output.push({
//         name: 'forecastStreamEnabled',
//         type: 'switcher',
//         options: [
//           { icon: 'check-mark', iconSize: iconSize.tick, value: false },
//           { icon: 'close', iconSize: iconSize.close, value: true },
//         ],
//         label: 'Live data',
//         formView: EFormVariant.KpiSettings,
//         disabled: !values.allowExternalConnection,
//       });
//     }
//   }

//   return output;
// };

/*
 * Fields used to show API / Properties tabs
 * Canary Networks
 */
export const CANARY_PROPERTIES_FIELDS = ({
  //type,
  //values = {},
  configType,
}: Pick<TAssetFieldTemplatesArgs, 'configType'>): TFieldsTemplateUnion[] => {
  const output: TFieldsTemplateUnion[] = [];
  // if (['Load', 'PV'].includes(type)) {
  if (configType === 'CANARY_NETWORK') {
    output.push({
      name: 'forecastStreamEnabled',
      type: 'switcher',
      options: [
        { icon: 'close', iconSize: SWITCHER_ICON_SIZES.close, value: false },
        { icon: 'check-mark', iconSize: SWITCHER_ICON_SIZES.tick, value: true },
      ],
      label: 'Live data',
      formView: EFormVariant.Advanced,
    });
    output.push({
      name: 'allowExternalConnection',
      type: 'switcher',
      options: [
        { icon: 'close', iconSize: SWITCHER_ICON_SIZES.close, value: false },
        { icon: 'check-mark', iconSize: SWITCHER_ICON_SIZES.tick, value: true },
      ],
      label: 'API Open',
      formView: EFormVariant.CanaryNetwork,
      disabled: true,
    });
  }
  // }

  return output;
};

// In most cases, these will be the fields which should be displayed when viewing
// a new or existing node.
function baseFieldTemplates({
  type,
  settingsData,
  values = {},
  isLibrary,
  configType,
  configurationCharacteristic,
  isSCM,
}: TAssetFieldTemplatesArgs): TFieldsTemplateUnion[] {
  // const evaluatedDeviceKpisFields = KPI_API_PROPERTIES_FIELDS({
  //   kpis: 'kpis' in settingsData ? settingsData.kpis.Device : [],
  //   type,
  //   values,
  //   configType,
  //   isLibrary,
  // });
  // const evaluatedMarketKpisFields = KPI_API_PROPERTIES_FIELDS({
  //   kpis: 'kpis' in settingsData ? settingsData.kpis.Area : [],
  //   type,
  //   values,
  //   configType,
  //   isLibrary,
  // });
  const evaluatedCanaryNetworkFields = CANARY_PROPERTIES_FIELDS({
    //type,
    //values,
    configType,
  });

  const basicLibraryFields = [
    {
      name: 'description',
      type: 'textarea',
      label: 'Description',
      formView: EFormVariant.BasicLibrarySettings,
    },
    {
      name: 'isPrivate',
      type: 'switcher',
      options: [
        { icon: 'close', iconSize: SWITCHER_ICON_SIZES.close, value: false },
        { icon: 'check-mark', iconSize: SWITCHER_ICON_SIZES.tick, value: true },
      ],
      label: 'Private Asset',
      formView: EFormVariant.BasicLibrarySettings,
    },
  ] as TFieldsTemplateUnion[];

  switch (type) {
    /* Area */
    case 'Area':
      return AreaPhoenix({ values, isLibrary });

    /* FiniteDieselGenerator */
    case 'FiniteDieselGenerator':
      return [
        {
          name: 'libraryUUID',
          formView: EFormVariant.Express,
        },
        {
          name: 'name',
          type: 'text',
          label: 'Name',
          formView: EFormVariant.Express,
        },
        {
          name: 'geoTagLocation',
          type: 'location',
          label: 'Location',
          tooltipText: 'Parent market already has location',
          EXCLUDE: isLibrary,
          formView: EFormVariant.Express,
        },
        // {
        //   name: 'count',
        //   type: 'counter',
        //   label: 'Multiply same unit',
        //   min: 1,
        //   formView: EFormVariant.Express,
        // },
        {
          name: 'energyRate',
          type: 'number',
          label: 'Energy rate',
          unit: 'cents / kWh',
          formView: EFormVariant.Advanced,
        },
        {
          name: 'maxAvailablePowerKw',
          type: 'number',
          label: 'Maximum available power',
          unit: 'kW',
          formView: EFormVariant.Advanced,
        },
      ];

    /* Load */
    case 'Load':
      return [
        {
          name: 'libraryUUID',
          formView: EFormVariant.Express,
        },
        {
          name: 'name',
          type: 'text',
          label: 'Asset Name',
          formView: EFormVariant.Express,
        },
        {
          name: 'geoTagLocation',
          type: 'location',
          label: 'Location',
          tooltipText: 'Parent market already has location',
          EXCLUDE: isLibrary,
          formView: EFormVariant.Express,
        },
        // {
        //   name: 'count',
        //   type: 'counter',
        //   label: 'Multiply same unit',
        //   min: 1,
        //   formView: EFormVariant.Express,
        // },
        {
          name: 'loadProfileOption',
          type: 'enum',
          label: 'Load Profile',
          options: [
            { value: 'userConfigure', label: 'User configure profile' },
            { value: 'userUpload', label: 'User upload profile' },
          ],
          formView: EFormVariant.Advanced,
        },
        {
          name: 'avgPowerW',
          type: 'number',
          label: 'Average Power',
          unit: 'W',
          formView: EFormVariant.Advanced,
          EXCLUDE: values.loadProfileOption !== 'userConfigure',
        },
        /*{
          name: 'hrsPerDay',
          type: 'slider',
          label: 'Hours per day',
          step: 1,
          // labelStep: 2,
          minVal: 0,
          maxVal: 24,
          formView: EFormVariant.Advanced,
          EXCLUDE: values.loadProfileOption !== 'userConfigure',
        },
        {
          name: 'hrsOfDay',
          type: 'slider',
          label: 'Hours of day',
          step: 1,
          // labelStep: 2,
          minVal: 0,
          maxVal: 23,
          formView: EFormVariant.Advanced,
          EXCLUDE: values.loadProfileOption !== 'userConfigure',
        },*/
        {
          name: 'dailyLoadProfile',
          type: 'file',
          label: 'Upload Profile',
          tooltipText: 'Please upload user load profile here',
          formView: EFormVariant.Advanced,
          EXCLUDE: values.loadProfileOption !== 'userUpload',
        },
        ...basicLibraryFields,
        ...evaluatedCanaryNetworkFields,
        {
          name: 'dailyLoadProfileUuid',
          formView: EFormVariant.Express,
          EXCLUDE: values.loadProfileOption !== 'userUpload',
        },
        {
          name: 'initialBuyingRate',
          type: 'number',
          label: 'Initial Buying Rate',
          unit: 'cents / kWh',
          EXCLUDE: settingsData.spotMarketType !== SpotMarketType.TwoSided,
          formView: EFormVariant.Advanced,
        },
        {
          name: 'useMarketMakerRate',
          type: 'enum',
          label: 'Final Buying Rate',
          options: [
            { value: true, label: 'Market maker rate' },
            { value: false, label: 'User input' },
          ],
          formView: EFormVariant.Advanced,
          EXCLUDE: false,
        },
        {
          name: 'finalBuyingRate',
          type: 'number',
          unit: 'cents / kWh',
          formView: EFormVariant.Advanced,
          EXCLUDE: values.useMarketMakerRate,
        },
        {
          name: 'energyRateIncreasePerUpdate',
          type:
            configurationCharacteristic.gridMakerHasUploadedProfile && values.fitToLimit
              ? 'text'
              : 'number',
          label: 'Rate increase',
          unit: 'cents / kWh per update',
          disabled: configurationCharacteristic.gridMakerHasUploadedProfile && values.fitToLimit,
          EXCLUDE: settingsData.spotMarketType !== SpotMarketType.TwoSided,
          formView: EFormVariant.Advanced,
        } as TNumberFieldTemplate | TTextFieldTemplate,
        {
          name: 'fitToLimit',
          type: 'checkbox',
          label: 'Linear Pricing',
          EXCLUDE: settingsData.spotMarketType !== SpotMarketType.TwoSided,
          formView: EFormVariant.Advanced,
        },
        {
          name: 'updateInterval',
          type: 'slider',
          label: 'Update Interval',
          unit: 'min',
          showUnit: true,
          step: 1,
          // labelStep: settingsData.slotLengthMinutes - 2,
          minVal: 1,
          maxVal: settingsData.slotLengthMinutes - 1,
          EXCLUDE: settingsData.spotMarketType !== SpotMarketType.TwoSided,
          formView: EFormVariant.Advanced,
        },
      ];
    /* HeatPump */
    case 'HeatPump':
      const args = {
        isLibrary,
        values,
        configurationCharacteristic,
        settingsData,
        configType,
        isSCM,
      };
      return HeatPumpPhoenix(args);

    /* PV */
    case 'PV': {
      return PVPhoenix({
        isLibrary,
        values,
        configurationCharacteristic,
        settingsData,
        configType,
      });
    }

    /* Storage */
    case 'Storage':
      return StoragePhoenix({ isLibrary, settingsData });

    /* WindTurbine */
    case 'WindTurbine':
      return WindTurbinePhoenix({
        isLibrary,
        values,
        configurationCharacteristic,
        settingsData,
      });

    /* SmartMeter */
    case 'SmartMeter':
      return SmartMeterPhoenix({ isLibrary, settingsData });

    /* MarketMaker */
    case 'MarketMaker':
      return [
        {
          name: 'name',
          type: 'text',
          label: 'Name',
          formView: EFormVariant.Express,
        },
        {
          name: GRID_MARKET_MODE,
          type: 'enum',
          label: 'Mode',
          options: [
            { value: 'MarketMakerMode', label: 'Infinite power plant' },
            { value: 'InfiniteBusMode', label: 'Infinite bus' },
          ],
          formView: EFormVariant.Advanced,
        },
        {
          name: 'gridConnected',
          type: 'enum',
          label: 'Role',
          options: [
            { value: true, label: 'Grid connected' },
            { value: false, label: 'Islanded' },
          ],
          formView: EFormVariant.Advanced,
        },
        {
          name: 'energyRateType',
          type: 'enum',
          label: 'Selling rate type',
          options: [
            { value: 0, label: 'User Input' },
            { value: 1, label: 'File Upload' },
          ],
          formView: EFormVariant.Advanced,
        },
        {
          name: 'energyRateProfile',
          type: 'file',
          label: 'Selling rate profile',
          placeholder: 'Choose a file',
          formView: EFormVariant.Advanced,
        },
        {
          name: 'energyRate',
          type: 'number',
          label: 'Selling rate',
          unit: 'cents / kWh',
          formView: EFormVariant.Advanced,
          EXCLUDE: configurationCharacteristic.gridMakerHasUploadedProfile,
        },
        {
          name: 'energyRateProfileUuid',
          formView: EFormVariant.Express,
        },
        {
          name: 'libraryUUID',
          formView: EFormVariant.Express,
        },
      ];
    /* InfiniteBus */
    case 'InfiniteBus':
      return InfiniteBusPhoenix({ values, isLibrary, configurationCharacteristic });

    default:
      throw new Error(`Invalid type passed to getAssetFieldTemplates function - ${type}`);
  }
}

export function getAllFieldTemplatesForAsset({
  values = defaultValues,
  ...args
}: TAssetFieldTemplatesArgs): ReturnType<typeof baseFieldTemplates> {
  return baseFieldTemplates({ values, ...args });
}

export function getSingleFieldTemplateForAsset({
  name,
  values = defaultValues,
  ...args
}: {
  name: string;
} & TAssetFieldTemplatesArgs): TFieldsTemplateUnion | undefined {
  return baseFieldTemplates({ values, ...args }).find((f) => f.name === name);
}
