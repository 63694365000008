import React, { useMemo, useState } from 'react';

import { BaseIcon } from 'src/components/BaseIcon';
import { Fade } from 'react-reveal';
import { TabNav } from 'src/components/TabNav';
import classnames from 'classnames';
import keyResultsStyles from 'src/components/SimulationResultsPresentation/KeyResults/KeyResults.module.scss';

import { ETab } from './CarbonEmissions.types';
import s from './CarbonEmissions.module.scss';
import { useImportedExportedEnergyCommunityQuery } from 'src/graphql';
import { useSelector } from 'react-redux';
import {
  selectActiveConfigurationJobUuid,
  selectCommunityAsset,
  selectSelectedAssetUuid,
  selectSettingsData,
} from 'src/redux/configuration/configuration.selectors';
import { BACKEND_DATE_FORMATS, UTCMoment } from 'src/utils/UTCMoment';

export const KPI_TO_TOOLTIP = {
  [ETab.CarbonFootprint]:
    "Measures community's emissions by correlating the imported electricity with the average CO₂-equivalent emissions of the country of location.",
  [ETab.CarbonSavings]:
    'Reduction in carbon footprint as a result of diminished electricity import and increased use of local renewables by engaging in community energy trading.',
};

function normalizePercentage(value = 0) {
  return (value / 1000).toFixed(2);
}

export type TCarbonEmissions = {
  carbon_generated_base_case_g: number;
  carbon_savings_g: number;
};

export const CarbonEmissions: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState(ETab.CarbonFootprint);
  const [isVisible, setIsVisible] = useState(true);

  const settingsData = useSelector(selectSettingsData);
  const assetUuid = useSelector(selectSelectedAssetUuid);
  const jobId = useSelector(selectActiveConfigurationJobUuid);
  const communtiyAsset = useSelector(selectCommunityAsset);

  const startTime = useMemo(() => {
    return UTCMoment.fromBackend(settingsData.startDate).format(
      BACKEND_DATE_FORMATS.SIMULATION_RESULTS_START_END_TIME,
    );
  }, [settingsData]);

  const endTime = useMemo(() => {
    return UTCMoment.fromBackend(settingsData.endDate)
      .endOf('day')
      .format(BACKEND_DATE_FORMATS.SIMULATION_RESULTS_START_END_TIME);
  }, [settingsData]);

  const toggleVisible = () => setIsVisible((prevState) => !prevState);
  const visibilityIcon = isVisible ? 'eye-no' : 'eye';

  const { data: carbonEmissionsResponse } = useImportedExportedEnergyCommunityQuery({
    fetchPolicy: 'cache-first',
    skip: !jobId,
    variables: {
      jobId: jobId!,
      areaUuid: assetUuid!,
      startTime,
      endTime,
    },
  });
  const carbonEmissions: TCarbonEmissions | null = useMemo(() => {
    const assetUuidToCarbonEmissions = !!carbonEmissionsResponse?.importedExportedEnergyCommunity
      ?.carbonEmissions
      ? JSON.parse(
          JSON.parse(carbonEmissionsResponse?.importedExportedEnergyCommunity?.carbonEmissions),
        )
      : null;
    if (!assetUuidToCarbonEmissions || !communtiyAsset?.uuid) return null;
    const selectedKey = assetUuid === communtiyAsset!.uuid ? 'total' : assetUuid;
    return !!selectedKey ? assetUuidToCarbonEmissions[selectedKey] : null;
  }, [carbonEmissionsResponse, communtiyAsset, assetUuid]);

  const value: number | null = useMemo(() => {
    if (!carbonEmissions) return null;
    return selectedTab === ETab.CarbonFootprint
      ? carbonEmissions.carbon_generated_base_case_g
      : carbonEmissions.carbon_savings_g;
  }, [carbonEmissions, selectedTab]);

  return (
    <div className={keyResultsStyles.container}>
      <div className={keyResultsStyles.header}>
        <div className={keyResultsStyles.title}>🌳 Decarbonization</div>
        <button type="button" onClick={toggleVisible} className={keyResultsStyles.toggle}>
          <span>{isVisible ? 'Hide' : 'Show'}</span>
          <BaseIcon icon={visibilityIcon} size={11} />
        </button>
      </div>
      <Fade collapse={!isVisible} when={isVisible} duration={1000}>
        {isVisible && (
          <>
            <TabNav
              className={classnames(keyResultsStyles.keyResultsTabs, s.carbonEmissionsTabs)}
              theme="1"
              nav={[
                {
                  label: ETab.CarbonFootprint,
                  onClick() {
                    setSelectedTab(ETab.CarbonFootprint);
                  },
                  isActive: selectedTab === ETab.CarbonFootprint,
                },
                {
                  label: ETab.CarbonSavings,
                  onClick() {
                    setSelectedTab(ETab.CarbonSavings);
                  },
                  isActive: selectedTab === ETab.CarbonSavings,
                },
              ]}
            />
            <div
              className={classnames(
                keyResultsStyles.assetInfo,
                keyResultsStyles.block,
                s.assetInfo,
              )}>
              <header>
                <div className={keyResultsStyles.earningStatus}>{selectedTab}</div>
              </header>
              <div className={keyResultsStyles.cost}>
                <span>{`${value !== null ? normalizePercentage(value) : '-'} kg`}</span>
              </div>
              <footer>
                <p className={keyResultsStyles.footerText}>{KPI_TO_TOOLTIP[selectedTab]}</p>
              </footer>
            </div>
          </>
        )}
      </Fade>
    </div>
  );
};
