import { BACKEND_DATE_FORMATS, UTCMoment } from 'src/utils/UTCMoment';
import { BidOfferMatchAlgo, ConfigType, Currencies, SpotMarketType } from 'src/graphql';
import {
  EHeatPumpSourceType,
  TValuesByFieldName,
} from 'src/utils/assetsFields/valuesByFieldName.types';
import {
  TAssetFieldTemplatesArgs,
  TCloudCoverageValues,
  TSettingsData,
} from 'src/utils/assetsFields/assetsFields.types';
import { GRID_MARKET_MODE, MAX_ENERGY_RATE_CHANGE_PER_UPDATE } from 'src/constants/application';
import { isNil, isNull } from 'lodash';

import { store } from 'src/redux/store';

const DEFAULT_MARKET_MAKER_RATE = 30;

export function settingsData(): TSettingsData {
  const today = UTCMoment.utc();

  return {
    slotLengthMinutes: 15,
    spotMarketType: SpotMarketType.TwoSided,
    bidOfferMatchAlgo: BidOfferMatchAlgo.PayAsBid,
    marketCount: 1,
    endDate: today.format(BACKEND_DATE_FORMATS.SETTINGS_DATA),
    endDateTime: today.format(BACKEND_DATE_FORMATS.SETTINGS_DATA_END_DATE_TIME),
    slotLengthRealtimeSeconds: 0,
    tickLengthSeconds: 15,
    startDate: today.subtract(7, 'days').format(BACKEND_DATE_FORMATS.SETTINGS_DATA),
    currency: Currencies.Eur,
  };
}

export function scmsettingsData(): TSettingsData {
  const today = UTCMoment.utc();
  const tomorrow = UTCMoment.utc().add(7, 'day');

  return {
    slotLengthMinutes: 15,
    spotMarketType: SpotMarketType.TwoSided,
    bidOfferMatchAlgo: BidOfferMatchAlgo.PayAsClear,
    marketCount: 1,
    endDate: tomorrow.format(BACKEND_DATE_FORMATS.SETTINGS_DATA),
    endDateTime: tomorrow.format(BACKEND_DATE_FORMATS.SETTINGS_DATA_END_DATE_TIME),
    slotLengthRealtimeSeconds: 0,
    tickLengthSeconds: 15,
    startDate: today.format(BACKEND_DATE_FORMATS.SETTINGS_DATA),
    currency: Currencies.Eur,
  };
}

/*
  These are default field values for simulations with default global settings
*/
export function assetsFields({
  type,
  settingsData,
  isLibrary,
  configurationCharacteristic,
  values,
  configType,
  isCustomLoad,
  isCustomPV,
}: Pick<
  TAssetFieldTemplatesArgs,
  | 'type'
  | 'settingsData'
  | 'isLibrary'
  | 'configurationCharacteristic'
  | 'values'
  | 'configType'
  | 'isCustomLoad'
  | 'isCustomPV'
>): TValuesByFieldName {
  let isCN = configType !== undefined ? configType === ConfigType.CanaryNetwork : undefined;
  const currentState = store.getState();
  const isSCMFlow = currentState.scm.isSCMFlow;

  if (isCN === undefined) {
    isCN = currentState.configuration.configType
      ? currentState.configuration.configType === ConfigType.CanaryNetwork
      : undefined;
  }

  /*
    These are default field values which are derived from customized global settings and the
    configuration's market maker rate.
    If a simulation's global setting value can not be found, the default global settings value is used.

    Values are calculated based on the following rules:
      (slot_length / update_interval) - 1 >= 1  /// translatable to ///   update_interval >= slot_length
      number_of_available_updates = Math.max(  ((slot_length_in_minute / update_interval) - 1),  1  )
      energy_rate_change_per_update = (initial_buying_rate - final_buying_rate) / number_of_available_updates
      0 <= energy_rate_change_per_update <= 10000

    In addition, when a device's fitToLimit value is true, the backend sends null
    for energyRateDecreasePerUpdate and energyRateIncreasePerUpdate. The value we
    are displaying to users in the form is purely a presentational aid and needs to
    be calculated here.
  */
  function computedValues() {
    let defaultInitialSellingRate =
      configurationCharacteristic.marketMakerRate || DEFAULT_MARKET_MAKER_RATE;
    let defaultFinalSellingRate = 0;
    let defaultInitialBuyingRate = 0;
    let defaultFinalBuyingRate =
      configurationCharacteristic.marketMakerRate ||
      (String(configurationCharacteristic.marketMakerRate) === '0' ? 0 : DEFAULT_MARKET_MAKER_RATE);
    const defaultUpdateInterval = 1;

    //basic library

    const output: TValuesByFieldName = {
      allowExternalConnection: false,
      ...values,
    };
    switch (type) {
      case 'PV': {
        // output.updateInterval = Math.min(defaultUpdateInterval, settingsData.slotLengthMinutes - 1);
        output.updateInterval = output.updateInterval
          ? output.updateInterval
          : Math.min(defaultUpdateInterval, settingsData.slotLengthMinutes - 1);

        if (configurationCharacteristic.gridMakerHasUploadedProfile) {
          output.energyRateDecreasePerUpdate = 'Varying rate';
        } else if (isLibrary && configurationCharacteristic.marketMakerRate === null) {
          output.energyRateDecreasePerUpdate = undefined;
        } else if (output.fitToLimit) {
          // This section has been updated for PH-1207 bug.
          // It has the formula with the updateRateDecreaseOrIncrease function
          let calculatedRateDecrease =
            (defaultInitialSellingRate - defaultFinalSellingRate) /
            Math.max(settingsData.slotLengthMinutes / output.updateInterval - 1, 1);
          calculatedRateDecrease = Math.round(calculatedRateDecrease * 100) / 100;
          if (calculatedRateDecrease > MAX_ENERGY_RATE_CHANGE_PER_UPDATE) {
            output.fitToLimit = false;
            output.energyRateDecreasePerUpdate = MAX_ENERGY_RATE_CHANGE_PER_UPDATE;
          } else {
            output.energyRateDecreasePerUpdate = calculatedRateDecrease;
          }
        }
        output.forecastStreamEnabled = isNull(output.forecastStreamEnabled)
          ? false
          : output.forecastStreamEnabled;
        output.allowExternalConnection = output.forecastStreamEnabled ? false : true;

        if (isCustomPV && (!values || !values.cloudCoverage)) {
          output.cloudCoverage = 5 as TCloudCoverageValues;
        }
        break;
      }

      case 'Load': {
        if (settingsData.spotMarketType !== SpotMarketType.TwoSided) {
          output.initialBuyingRate = null;
          output.energyRateIncreasePerUpdate = undefined;
          output.updateInterval = null;
          output.fitToLimit = null;
        } else {
          output.updateInterval = output.updateInterval
            ? output.updateInterval
            : Math.min(defaultUpdateInterval, settingsData.slotLengthMinutes - 1);

          if (configurationCharacteristic.gridMakerHasUploadedProfile) {
            output.energyRateIncreasePerUpdate = 'Varying rate';
          } else if (isLibrary && configurationCharacteristic.marketMakerRate === null) {
            output.energyRateIncreasePerUpdate = DEFAULT_MARKET_MAKER_RATE;
          } else if (output.fitToLimit) {
            // As per PH-896
            let calculatedRateIncrease =
              (defaultFinalBuyingRate - defaultInitialBuyingRate) /
              Math.max(settingsData.slotLengthMinutes / output.updateInterval - 1, 1);
            calculatedRateIncrease = Math.round(calculatedRateIncrease * 100) / 100;
            if (calculatedRateIncrease > MAX_ENERGY_RATE_CHANGE_PER_UPDATE) {
              output.fitToLimit = false;
              output.energyRateIncreasePerUpdate = MAX_ENERGY_RATE_CHANGE_PER_UPDATE;
            } else {
              output.energyRateIncreasePerUpdate = calculatedRateIncrease;
            }
          }
        }
        if (values) {
          output.loadProfileOption = values.dailyLoadProfile ? 'userUpload' : 'userConfigure';
        }
        if (isCustomLoad && (!values || !values.dailyLoadProfile)) {
          output.loadProfileOption = 'userUpload';
          output.dailyLoadProfile = ' ';
        }
        output.forecastStreamEnabled = isNull(output.forecastStreamEnabled)
          ? false
          : output.forecastStreamEnabled;

        output.allowExternalConnection = output.forecastStreamEnabled ? false : true;

        break;
      }

      case 'HeatPump': {
        output.consumptionKwhProfile = output.consumptionKwhProfile
          ? output.consumptionKwhProfile
          : '';
        output.sourceTempCProfile = output.sourceTempCProfile ? output.sourceTempCProfile : '';

        if (output.finalBuyingRate === null) {
          output.finalBuyingRate = 0;
        }
        break;
      }

      case 'Storage': {
        defaultInitialSellingRate = 30;
        defaultFinalSellingRate = 25.1;
        defaultInitialBuyingRate = 0;
        defaultFinalBuyingRate = 25;
        output.updateInterval = output.updateInterval
          ? output.updateInterval
          : Math.min(defaultUpdateInterval, settingsData.slotLengthMinutes - 1);
        //output.updateInterval = Math.min(defaultUpdateInterval, settingsData.slotLengthMinutes - 1);

        let calculatedRateDecrease =
          (defaultInitialSellingRate - defaultFinalSellingRate) /
          Math.max(settingsData.slotLengthMinutes / output.updateInterval - 1, 1);
        calculatedRateDecrease = Math.round(calculatedRateDecrease * 100) / 100;

        if (calculatedRateDecrease > MAX_ENERGY_RATE_CHANGE_PER_UPDATE) {
          output.fitToLimit = false;
          output.energyRateDecreasePerUpdate = MAX_ENERGY_RATE_CHANGE_PER_UPDATE;
        } else if (values?.fitToLimit) {
          output.energyRateDecreasePerUpdate = calculatedRateDecrease;
        }

        let calculatedRateIncrease =
          (defaultFinalBuyingRate - defaultInitialBuyingRate) /
          Math.ceil(settingsData.slotLengthMinutes / output.updateInterval - 1);
        calculatedRateIncrease = Math.round(calculatedRateIncrease * 100) / 100;

        output.allowExternalConnection = isCN ? true : false;
        if (calculatedRateIncrease > MAX_ENERGY_RATE_CHANGE_PER_UPDATE) {
          output.fitToLimit = false;
          output.energyRateIncreasePerUpdate = MAX_ENERGY_RATE_CHANGE_PER_UPDATE;
        } else if (values?.fitToLimit) {
          output.energyRateIncreasePerUpdate = calculatedRateIncrease;
        }
        break;
      }

      case 'FiniteDieselGenerator':
        break;

      case 'MarketMaker':
      case 'InfiniteBus': {
        if (values) {
          output.energyRateType = values.energyRateProfile ? 1 : 0;
          if (values.GRID_MARKET_MODE === 'InfiniteBusMode') {
            output.gridConnected = true;
          }
        }

        output.marketMakerRate = values?.marketMakerRate || undefined;
        output.feedInTariff = values?.feedInTariff || undefined;

        output.energyBuyRate = values?.energyBuyRate ? values.energyBuyRate : isSCMFlow ? 0 : 12;
        output.energyRate = values?.energyRate ? values.energyRate : isSCMFlow ? 0 : 30;

        output.gridFeeEnabled =
          !isNil(values?.gridFeeConstant) || !isNil(values?.gridFeePercentage);
        break;
      }

      case 'Area':
        output.coefficientPercentage = !isNil(values?.coefficientPercentage)
          ? values?.coefficientPercentage
          : 0;
        output.gridFeeEnabled =
          !isNil(values?.gridFeeConstant) || !isNil(values?.gridFeePercentage);
        output.transformerCapacityEnabled =
          !isNil(values?.importCapacityKva) || !isNil(values?.exportCapacityKva);
        output.baselinePeakEnergyEnabled =
          !isNil(values?.baselinePeakEnergyImportKwh) ||
          !isNil(values?.baselinePeakEnergyExportKwh);

        output.taxesSurcharges = values?.taxesSurcharges || 0;
        output.fixedMonthlyFee = values?.fixedMonthlyFee || 0;
        output.marketMakerRate = values?.marketMakerRate || 0;
        output.feedInTariff = values?.feedInTariff || 0;
        output.marketplaceMonthlyFee = values?.marketplaceMonthlyFee || 0;
        //make it for assistanceMonthlyFee
        output.assistanceMonthlyFee = values?.assistanceMonthlyFee || 0;
        break;
    }

    return output;
  }

  //basic library
  const basicLibrary = { description: '', isPrivate: true };

  switch (type) {
    /* Area */
    case 'Area':
      return {
        count: 1,
        name: 'Market',
        gridFeeConstant: 0,
        gridFeePercentage: 0,
        geoTagLocation: null,
        exchangeInformation: 'spot',
        importCapacityKva: 0,
        exportCapacityKva: 0,
        coefficientPercentage: 0,
        baselinePeakEnergyImportKwh: 0,
        baselinePeakEnergyExportKwh: 0,
        geoTagType: 'area',
        fitAreaBoundary: true,
        marketplaceMonthlyFee: 0,
        assistanceMonthlyFee: 0,
        fixedMonthlyFee: 0,
        taxesSurcharges: 0,
        marketMakerRate: 0,
        ...computedValues(),
        ...basicLibrary,
      };
    /* FiniteDieselGenerator */
    case 'FiniteDieselGenerator':
      return {
        count: 1,
        name: 'Power Plant',
        geoTagLocation: null,
        energyRate: 30,
        maxAvailablePowerKw: 1000,
        ...computedValues(),
        ...basicLibrary,
      };

    /* Load */
    case 'Load':
      return {
        count: 1,
        name: 'Load',
        geoTagLocation: null,
        avgPowerW: 100,
        //hrsPerDay: 9,
        //hrsOfDay: [8, 17],
        initialBuyingRate: 0,
        useMarketMakerRate: true,
        energyRateIncreasePerUpdate: 5,
        fitToLimit: true,
        updateInterval: 1,
        forecastStreamEnabled: false,
        ...computedValues(),
        ...basicLibrary,
      };

    case 'HeatPump':
      return {
        count: 1,
        name: 'HeatPump',
        geoTagLocation: null,
        initialBuyingRate: 0,
        preferredBuyingRate: 15,
        updateInterval: 1,
        maximumPowerRatingKw: 10,
        minTempC: 25,
        maxTempC: 60,
        initialTempC: 25,
        tankVolumeL: 500,
        sourceType: EHeatPumpSourceType.Air || EHeatPumpSourceType.Ground,
        useMarketMakerRate: true,
        finalBuyingRate: 0,
        ...computedValues(),
        ...basicLibrary,
      };

    /* PV */
    case 'PV': {
      return {
        count: 1,
        name: 'PV',
        geoTagLocation: null,
        capacityKw: 5,
        cloudCoverage: 0,
        useMarketMakerRate: true,
        finalSellingRate: 0,
        energyRateDecreasePerUpdate: 5,
        fitToLimit: true,
        updateInterval: 1,
        forecastStreamEnabled: false,
        ...computedValues(),
        ...basicLibrary,
      };
    }

    /* Storage */
    case 'Storage': {
      const defaultBatteryCapacityKwh = 5;
      const initialSoc = 10;

      return {
        count: 1,
        name: 'Battery',
        batteryCapacityKwh: values?.batteryCapacityKwh ?? defaultBatteryCapacityKwh,
        initialkWh:
          Math.round(initialSoc * (values?.batteryCapacityKwh ?? defaultBatteryCapacityKwh)) / 100,
        minAllowedSoc: initialSoc,
        maxAbsBatteryPowerKw: 5,
        initialSellingRate: 30,
        finalSellingRate: 25.1,
        energyRateDecreasePerUpdate: 5,
        initialBuyingRate: 0,
        finalBuyingRate: 25,
        energyRateIncreasePerUpdate: 5,
        fitToLimit: true,
        updateInterval: 1,
        capPriceStrategy: false,
        forecastStreamEnabled: false,
        ...computedValues(),
        ...basicLibrary,
      };
    }

    /* WindTurbine */
    case 'WindTurbine': {
      return {
        count: 1,
        name: 'Wind Turbine',
        geoTagLocation: null,
        capacityKw: 5,
        initialSellingRate: 30,
        finalSellingRate: 25.1,
        energyRateDecreasePerUpdate: 5,
        fitToLimit: true,
        updateInterval: 1,
        powerProfile: ' ',
        ...computedValues(),
        ...basicLibrary,
      };
    }

    /* SmartMeter */
    case 'SmartMeter': {
      return {
        count: 1,
        name: 'Smart Meter',
        initialSellingRate: 30,
        finalSellingRate: 25.1,
        energyRateDecreasePerUpdate: 5,
        energyRateIncreasePerUpdate: 5,
        initialBuyingRate: 0,
        finalBuyingRate: 25,
        fitToLimit: true,
        updateInterval: 1,
        ...computedValues(),
        ...basicLibrary,
      };
    }

    /* Market Maker */
    case 'MarketMaker':
    case 'InfiniteBus':
      return {
        name: 'Grid Market',
        [GRID_MARKET_MODE]: type === 'MarketMaker' ? 'MarketMakerMode' : 'InfiniteBusMode',
        gridConnected: true,
        energyRateType: 0,
        energyRate: DEFAULT_MARKET_MAKER_RATE,
        buyingRateType: 0,
        energyBuyRate: 0,
        geoTagType: 'area',
        exchangeInformation: 'spot',
        gridFeeEnabled: false,
        gridFeeConstant: 0,
        gridFeePercentage: 0,
        marketplaceMonthlyFee: 0,
        assistanceMonthlyFee: 0,
        fixedMonthlyFee: 0,
        taxesSurcharges: 0,
        gridFee: 0,
        marketMakerRate: 0,
        feedInTariff: 0,
        ...computedValues(),
        ...basicLibrary,
      };
  }
}

export const fieldValues = {
  DEFAULT_MARKET_MAKER_RATE,
  settingsData,
  scmsettingsData,
  assetsFields,
};
